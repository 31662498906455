@use "sass:math";
@import "../variables/base";
@import "../functions/functions";

@mixin _core-font-generator($font-size: 19px, $font-size-640: 16px, $font-size-print: 14pt, $line-height: math.div(25 , 19), $line-height-640: math.div(20 , 16), $font-weight: 400) {
  font-family: $helvetica-regular;
  font-weight: $font-weight;
  text-transform: none;

  @if $is-print {
    font-size: $font-size-print;
    line-height: $line-height;

  } @else {
    font-size: $font-size-640;
    line-height: $line-height-640;

    @include media(tablet) {
      font-size: $font-size;
      line-height: $line-height;
    }
  }
}

@mixin core-80($line-height:math.div(80 , 80), $line-height-640:math.div(55 , 53), $font-weight: 400) {
  $font-size: 80px;
  $font-size-640: 53px;
  $font-size-print: 28pt;
  @include _core-font-generator($font-size, $font-size-640, $font-size-print, $line-height, $line-height-640, $font-weight);
}

@mixin core-48($line-height: math.div(50 , 48), $line-height-640: math.div(35 , 32), $font-weight: 400) {
  $font-size: 48px;
  $font-size-640: 32px;
  $font-size-print: 18pt;
  @include _core-font-generator($font-size, $font-size-640, $font-size-print, $line-height, $line-height-640, $font-weight);
}

@mixin core-36($line-height: math.div(40 , 36), $line-height-640: math.div(25 , 24), $font-weight: 400) {
  $font-size: 36px;
  $font-size-640: 24px;
  $font-size-print: 18pt;
  @include _core-font-generator($font-size, $font-size-640, $font-size-print, $line-height, $line-height-640, $font-weight);
}

@mixin core-27($line-height: math.div(30 , 27), $line-height-640: math.div(20 , 18), $font-weight: 400) {
  $font-size: 27px;
  $font-size-640: 20px;
  $font-size-print: 16pt;
  @include _core-font-generator($font-size, $font-size-640, $font-size-print, $line-height, $line-height-640, $font-weight);
}

@mixin core-24($line-height: math.div(30 , 24), $line-height-640: math.div(24 , 20), $font-weight: 400) {
  $font-size: 24px;
  $font-size-640: 18px;
  $font-size-print: 16pt;
  @include _core-font-generator($font-size, $font-size-640, $font-size-print, $line-height, $line-height-640, $font-weight);
}

@mixin core-22($line-height: math.div(27 , 22), $line-height-640: math.div(22 , 18), $font-weight: 400) {
  $font-size: 22px;
  $font-size-640: 16px;
  $font-size-print: 14pt;
  @include _core-font-generator($font-size, $font-size-640, $font-size-print, $line-height, $line-height-640, $font-weight);
}

@mixin core-19($line-height: math.div(25 , 19), $line-height-640: math.div(20 , 16), $font-weight: 400) {
  $font-size: 19px;
  $font-size-640: 16px;
  $font-size-print: 14pt;
  @include _core-font-generator($font-size, $font-size-640, $font-size-print, $line-height, $line-height-640, $font-weight);
}

@mixin core-16($line-height: math.div(20 , 16), $line-height-640: math.div(16 , 14), $font-weight: 400) {
  $font-size: 16px;
  $font-size-640: 14px;
  $font-size-print: 12pt;
  @include _core-font-generator($font-size, $font-size-640, $font-size-print, $line-height, $line-height-640, $font-weight);
}

@mixin core-14($line-height: math.div(20 , 14), $line-height-640: math.div(15 , 12), $font-weight: 400) {
  $font-size: 14px;
  $font-size-640: 12px;
  $font-size-print: 11pt;
  @include _core-font-generator($font-size, $font-size-640, $font-size-print, $line-height, $line-height-640, $font-weight);
}

@mixin bold-80($line-height: math.div(80 , 80), $line-height-640: math.div(55 , 53) ) {
  @include core-80($line-height, $line-height-640, $font-weight: 700);
  
}

@mixin bold-48($line-height: math.div(50 , 48), $line-height-640: math.div(35 , 32)) {
  @include core-48($line-height, $line-height-640, $font-weight: 400);
  font-family: 'helvetica_bold';
}

@mixin bold-36($line-height: math.div(40 , 36), $line-height-640: math.div(25 , 24)) {
  @include core-36($line-height, $line-height-640, $font-weight: 400);
  font-family: 'helvetica_bold';
}

@mixin bold-27($line-height: math.div(30 , 27), $line-height-640: math.div(20 , 18)) {
  @include core-27($line-height, $line-height-640, $font-weight: 700);
  font-family: 'helvetica_bold';
}

@mixin bold-24($line-height: math.div(30 , 24), $line-height-640: math.div(24 , 20)) {
  @include core-24($line-height, $line-height-640, $font-weight: 400);
  font-family: 'helvetica_bold';
}

@mixin bold-22($line-height: math.div(27 , 22), $line-height-640: math.div(22 , 18)) {
  @include core-22($line-height, $line-height-640, $font-weight: 700);
  font-family: 'helvetica_bold';
}

@mixin bold-19($line-height: math.div(25 , 19), $line-height-640: math.div(20 , 16)) {
  @include core-19($line-height, $line-height-640, $font-weight: 400);
  font-family: 'helvetica_bold';
}

@mixin bold-16($line-height: math.div(20 , 16), $line-height-640: math.div(16 , 14)) {
  @include core-16($line-height, $line-height-640, $font-weight: 400);
  font-family: 'helvetica_bold';
}

@mixin bold-14($line-height: math.div(20 , 14), $line-height-640: math.div(15 , 12)) {
  @include core-14($line-height, $line-height-640, $font-weight: 400);
  font-family: 'helvetica_bold';
}

@mixin heading-80() {
  @include core-80();

  display: block;

  padding-top: 8px;
  padding-bottom: 7px;

  @include media(tablet) {
    padding-top: 6px;
    padding-bottom: 14px;
  }
}

@mixin heading-48() {
  @include core-48();

  display: block;

  padding-top: 10px;
  padding-bottom: 10px;

  @include media(tablet) {
    padding-top: 7px;
    padding-bottom: 13px;
  }
}

@mixin heading-36() {
  @include core-36();

  display: block;

  padding-top: 8px;
  padding-bottom: 7px;

  @include media(tablet) {
    padding-top: 6px;
    padding-bottom: 9px;
  }
}

@mixin heading-27() {
  @include core-27();

  display: block;

  padding-top: 8px;
  padding-bottom: 7px;

  @include media(tablet) {
    padding-top: 4px;
    padding-bottom: 6px;
  }
}

@mixin heading-24() {
  @include core-24();

  display: block;

  padding-top: 9px;
  padding-bottom: 6px;

  @include media(tablet) {
    padding-top: 6px;
    padding-bottom: 4px;
  }
}
@mixin copy-22() {
  @include core-22();

  padding-top: 2px;
  padding-bottom: 8px;

  @include media(tablet) {
    padding-top: 0;
    padding-bottom: 5px;
  }
}
@mixin copy-19() {
  @include core-19();

  padding-top: 2px;
  padding-bottom: 8px;

  @include media(tablet) {
    padding-top: 0;
    padding-bottom: 5px;
  }
}

@mixin copy-16() {
  @include core-16();

  padding-top: 8px;
  padding-bottom: 7px;

  @include media(tablet) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@mixin copy-14() {
  @include core-14();

  padding-top: 8px;
  padding-bottom: 7px;

  @include media(tablet) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
@mixin sentence-case() {
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
}

@mixin govuk-focus() {
  background-color: $govuk-focus-colour;
  box-shadow: 0 -2px $govuk-focus-colour, 0 4px $govuk-focus-text-colour;
  outline: 3px solid transparent;
  text-decoration: none;
  color: $govuk-focus-text-colour;
}
